/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /users/sign_in(.:format) */
export function newUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_in(.:format) */
export function userSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_out(.:format) */
export function destroyUserSessionPath(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/auth/google_oauth2(.:format) */
export function userGoogleOauth2OmniauthAuthorizePath(options?: object): string {
  return "/" + "users" + "/" + "auth" + "/" + "google_oauth2" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/auth/google_oauth2/callback(.:format) */
export function userGoogleOauth2OmniauthCallbackPath(options?: object): string {
  return "/" + "users" + "/" + "auth" + "/" + "google_oauth2" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_out(.:format) */
export function usersSignOutPath(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /login_with_otp(.:format) */
export function loginWithOTPPath(options?: object): string {
  return "/" + "login_with_otp" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /login(.:format) */
export function loginPath(options?: object): string {
  return "/" + "login" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/current_user(.:format) */
export function apiCurrentUserPath(options?: object): string {
  return "/" + "api" + "/" + "current_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/chat_messages(.:format) */
export function apiCoPilotChatMessagesPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "chat_messages" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/cancel_input(.:format) */
export function apiCoPilotCancelInputPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "cancel_input" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/upload_lease(.:format) */
export function apiCoPilotUploadLeasePath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "upload_lease" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/lease_status(.:format) */
export function apiCoPilotLeaseStatusPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "lease_status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/lease_details(.:format) */
export function apiCoPilotLeaseDetailsPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "lease_details" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/listings(.:format) */
export function apiCoPilotListingsPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "listings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/parcels/watchlist(.:format) */
export function apiCoPilotParcelsWatchlistPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + "watchlist" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/parcels/watchlist/:parcel_id(.:format) */
export function apiCoPilotDestroyWatchlistParcelPath(parcel_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + "watchlist" + "/" + parcel_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["parcel_id","format"]);
}

/** /api/copilot/parcels/search(.:format) */
export function searchApiCoPilotParcelsPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/parcels/:id/community_posts(.:format) */
export function communityPostsApiCoPilotParcelPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + id + "/" + "community_posts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/copilot/parcels/:id/private_posts(.:format) */
export function privatePostsApiCoPilotParcelPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + id + "/" + "private_posts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/copilot/parcels/community_posts/:id/likes(.:format) */
export function createCommunityPostLikeApiCoPilotParcelsPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + "community_posts" + "/" + id + "/" + "likes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/copilot/parcels/community_posts/:id/likes(.:format) */
export function destroyCommunityPostLikeApiCoPilotParcelsPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + "community_posts" + "/" + id + "/" + "likes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/copilot/parcels(.:format) */
export function apiCoPilotParcelsPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/parcels/:id(.:format) */
export function apiCoPilotParcelPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "parcels" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/copilot/account/verify_referral_code(.:format) */
export function verifyReferralCodeApiCoPilotAccountPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "account" + "/" + "verify_referral_code" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/copilot/account(.:format) */
export function apiCoPilotAccountPath(options?: object): string {
  return "/" + "api" + "/" + "copilot" + "/" + "account" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/attribute_histories/:subject_type/:subject_id/:attribute_name(.:format) */
export function apiInternalAttributeHistoriesPath(subject_type: ScalarType, subject_id: ScalarType, attribute_name: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "attribute_histories" + "/" + subject_type + "/" + subject_id + "/" + attribute_name + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["subject_type","subject_id","attribute_name","format"]);
}

/** /api/internal/lead_submissions(.:format) */
export function apiInternalLeadSubmissionsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "lead_submissions" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/qualified_leads/:qualified_lead_id/disqualifications(.:format) */
export function apiInternalQualifiedLeadDisqualificationsPath(qualified_lead_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "qualified_leads" + "/" + qualified_lead_id + "/" + "disqualifications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["qualified_lead_id","format"]);
}

/** /api/internal/drive_paths/:id(.:format) */
export function apiInternalDrivePathPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "drive_paths" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/search_results(.:format) */
export function apiInternalSearchResultsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "search_results" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/company_search_results(.:format) */
export function apiInternalCompanySearchResultsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "company_search_results" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/companies/:company_id/attachment_download_all(.:format) */
export function apiInternalCompanyAttachmentDownloadAllIndexPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "attachment_download_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/real_estate_activities(.:format) */
export function apiInternalCompanyRealEstateActivitiesPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "real_estate_activities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/attachments(.:format) */
export function apiInternalCompanyAttachmentsPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "attachments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/attachments/:id(.:format) */
export function apiInternalCompanyAttachmentPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "attachments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /api/internal/companies/:company_id/diligence_searches(.:format) */
export function apiInternalCompanyDiligenceSearchesPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "diligence_searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/diligence_searches/:id(.:format) */
export function apiInternalCompanyDiligenceSearchPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "diligence_searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /api/internal/companies/:company_id/document_collection_completion(.:format) */
export function apiInternalCompanyDocumentCollectionCompletionIndexPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "document_collection_completion" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/financials(.:format) */
export function apiInternalCompanyFinancialsPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "financials" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/financials/:id(.:format) */
export function apiInternalCompanyFinancialPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "financials" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /api/internal/companies/:company_id/plaid_accounts(.:format) */
export function apiInternalCompanyPlaidAccountsPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "plaid_accounts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/plaid_accounts/:id(.:format) */
export function apiInternalCompanyPlaidAccountPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "plaid_accounts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /api/internal/companies/:company_id/plaid_items(.:format) */
export function apiInternalCompanyPlaidItemsPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "plaid_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/purchasing_power_letters(.:format) */
export function apiInternalCompanyPurchasingPowerLettersPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "purchasing_power_letters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/rutter_connections(.:format) */
export function apiInternalCompanyRutterConnectionsPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "rutter_connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/rutter_connections/:id(.:format) */
export function apiInternalCompanyRutterConnectionPath(company_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "rutter_connections" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","id","format"]);
}

/** /api/internal/companies/:company_id/subsidiaries(.:format) */
export function apiInternalCompanySubsidiariesPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "subsidiaries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/users(.:format) */
export function apiInternalCompanyUsersPath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies/:company_id/structure(.:format) */
export function apiInternalCompanyStructurePath(company_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + company_id + "/" + "structure" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["company_id","format"]);
}

/** /api/internal/companies(.:format) */
export function apiInternalCompaniesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/companies/:id(.:format) */
export function apiInternalCompanyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "companies" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/customer_leases(.:format) */
export function apiInternalCustomerLeasesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "customer_leases" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/payment_scenario_comparisons/:id(.:format) */
export function apiInternalPaymentScenarioComparisonPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "payment_scenario_comparisons" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/rutter_connections/:rutter_connection_id/rutter_categorizations(.:format) */
export function apiInternalRutterConnectionRutterCategorizationsPath(rutter_connection_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "rutter_connections" + "/" + rutter_connection_id + "/" + "rutter_categorizations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["rutter_connection_id","format"]);
}

/** /api/internal/market_info/addresses/:id(.:format) */
export function apiInternalMarketInfoAddressPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "market_info" + "/" + "addresses" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/deals/:id/promote(.:format) */
export function promoteApiInternalDealPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + id + "/" + "promote" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/deals/:deal_id/attachment_download_all(.:format) */
export function apiInternalDealAttachmentDownloadAllIndexPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "attachment_download_all" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/recommendations(.:format) */
export function apiInternalDealRecommendationsPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "recommendations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/attachments(.:format) */
export function apiInternalDealAttachmentsPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "attachments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/attachments/:id(.:format) */
export function apiInternalDealAttachmentPath(deal_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "attachments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","id","format"]);
}

/** /api/internal/deals/:deal_id/files(.:format) */
export function apiInternalDealFilesPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "files" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/loi(.:format) */
export function apiInternalDealLoiPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "loi" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/preapproved_budget(.:format) */
export function apiInternalDealPreapprovedBudgetPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "preapproved_budget" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/property(.:format) */
export function apiInternalDealPropertyPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "property" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/proposals(.:format) */
export function apiInternalDealProposalsPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "proposals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/proposals/:id(.:format) */
export function apiInternalProposalPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "proposals" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/deals/:deal_id/real_estate_summary(.:format) */
export function apiInternalDealRealEstateSummaryPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "real_estate_summary" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/scenario_simulation(.:format) */
export function apiInternalDealScenarioSimulationPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "scenario_simulation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/smb_summary(.:format) */
export function apiInternalDealSMBSummaryPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "smb_summary" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:deal_id/task_groups(.:format) */
export function apiInternalDealTaskGroupsPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "task_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/tasks/:task_id/attachments(.:format) */
export function apiInternalTaskAttachmentsPath(task_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "tasks" + "/" + task_id + "/" + "attachments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["task_id","format"]);
}

/** /api/internal/tasks/:task_id/attachments/:id(.:format) */
export function apiInternalTaskAttachmentPath(task_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "tasks" + "/" + task_id + "/" + "attachments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["task_id","id","format"]);
}

/** /api/internal/tasks/:task_id/comments(.:format) */
export function apiInternalTaskCommentsPath(task_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "tasks" + "/" + task_id + "/" + "comments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["task_id","format"]);
}

/** /api/internal/tasks/:task_id/dependencies(.:format) */
export function apiInternalTaskDependenciesPath(task_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "tasks" + "/" + task_id + "/" + "dependencies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["task_id","format"]);
}

/** /api/internal/deals/:deal_id/tasks(.:format) */
export function apiInternalDealTasksPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "tasks" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/tasks/:id(.:format) */
export function apiInternalTaskPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "tasks" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/deals/:deal_id/ts(.:format) */
export function apiInternalDealTsPath(deal_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + deal_id + "/" + "ts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["deal_id","format"]);
}

/** /api/internal/deals/:id(.:format) */
export function apiInternalDealPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "deals" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/address_lookups(.:format) */
export function apiInternalAddressLookupsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "address_lookups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/attachment_types(.:format) */
export function apiInternalAttachmentTypesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "attachment_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/attachment_downloads/:id(.:format) */
export function apiInternalAttachmentDownloadPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "attachment_downloads" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/contacts/upload(.:format) */
export function apiInternalContactsUploadPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "contacts" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/costar_listings/upload(.:format) */
export function apiInternalCoStarListingsUploadPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "costar_listings" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/crexi_listings/upload(.:format) */
export function apiInternalCrexiListingsUploadPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "crexi_listings" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/dashboard_companies(.:format) */
export function apiInternalDashboardCompaniesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "dashboard_companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/dashboard_deals(.:format) */
export function apiInternalDashboardDealsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "dashboard_deals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/recommendation_deals(.:format) */
export function apiInternalRecommendationDealsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "recommendation_deals" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/employee_users(.:format) */
export function apiInternalEmployeeUsersPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "employee_users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/ppp_entries(.:format) */
export function apiInternalPPPEntriesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "ppp_entries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/ppp_entries/:id(.:format) */
export function apiInternalPPPEntryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "ppp_entries" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/industries/:id/revenue_cagr(.:format) */
export function revenueCagrApiInternalIndustryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "industries" + "/" + id + "/" + "revenue_cagr" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/industries/:id/revenue_history(.:format) */
export function revenueHistoryApiInternalIndustryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "industries" + "/" + id + "/" + "revenue_history" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/industries/:id/cost_ratios(.:format) */
export function costRatiosApiInternalIndustryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "industries" + "/" + id + "/" + "cost_ratios" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/industries(.:format) */
export function apiInternalIndustriesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "industries" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/files(.:format) */
export function apiInternalRealEstateActivityFilesPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "files" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/leasing_comps/upload(.:format) */
export function uploadApiInternalRealEstateActivityLeasingCompsPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "leasing_comps" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/leasing_comps(.:format) */
export function apiInternalRealEstateActivityLeasingCompsPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "leasing_comps" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/leasing_comps/:id(.:format) */
export function apiInternalRealEstateActivityLeasingCompPath(real_estate_activity_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "leasing_comps" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/places(.:format) */
export function apiInternalRealEstateActivityPlacesPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "places" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/property_data(.:format) */
export function apiInternalRealEstateActivityPropertyDataPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "property_data" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/reonomy_property_data(.:format) */
export function apiInternalRealEstateActivityReonomyPropertyDataPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "reonomy_property_data" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/sales_comps/upload(.:format) */
export function uploadApiInternalRealEstateActivitySalesCompsPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "sales_comps" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/sales_comps(.:format) */
export function apiInternalRealEstateActivitySalesCompsPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "sales_comps" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/sales_comps/:id(.:format) */
export function apiInternalRealEstateActivitySalesCompPath(real_estate_activity_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "sales_comps" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/underwriting_data_helpers(.:format) */
export function apiInternalRealEstateActivityUnderwritingDataHelpersPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "underwriting_data_helpers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/underwriting_model_inputs(.:format) */
export function apiInternalRealEstateActivityUnderwritingModelInputsPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "underwriting_model_inputs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities/:real_estate_activity_id/yelp_businesses(.:format) */
export function apiInternalRealEstateActivityYelpBusinessesPath(real_estate_activity_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + real_estate_activity_id + "/" + "yelp_businesses" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["real_estate_activity_id","format"]);
}

/** /api/internal/real_estate_activities(.:format) */
export function apiInternalRealEstateActivitiesPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/real_estate_activities/:id(.:format) */
export function apiInternalRealEstateActivityPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "real_estate_activities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/internal/preapproved_budget_requests(.:format) */
export function apiInternalPreapprovedBudgetRequestsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "preapproved_budget_requests" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/owners(.:format) */
export function apiInternalOwnersPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "owners" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/place_lookups(.:format) */
export function apiInternalPlaceLookupsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "place_lookups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/streetview(.:format) */
export function apiInternalStreetviewPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "streetview" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/submarkets(.:format) */
export function apiInternalSubmarketsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "submarkets" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/internal/underwriting_model_inputs/parse_tsv(.:format) */
export function parseTsvApiInternalUnderwritingModelInputsPath(options?: object): string {
  return "/" + "api" + "/" + "internal" + "/" + "underwriting_model_inputs" + "/" + "parse_tsv" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/attachments(.:format) */
export function apiPartnersAttachmentsPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "attachments" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/attachments/:id(.:format) */
export function apiPartnersAttachmentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "attachments" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/partners/attachment_downloads/:id(.:format) */
export function apiPartnersAttachmentDownloadPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "attachment_downloads" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/partners/attachment_types(.:format) */
export function apiPartnersAttachmentTypesPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "attachment_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/company(.:format) */
export function apiPartnersCompanyPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "company" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/deal/submit_financials(.:format) */
export function submitFinancialsApiPartnersDealPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "deal" + "/" + "submit_financials" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/deal/update_address(.:format) */
export function updateAddressApiPartnersDealPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "deal" + "/" + "update_address" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/users/:user_id/company(.:format) */
export function apiPartnersUserCompanyPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "users" + "/" + user_id + "/" + "company" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id","format"]);
}

/** /api/partners/users/:user_id/deal(.:format) */
export function apiPartnersUserDealPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "users" + "/" + user_id + "/" + "deal" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id","format"]);
}

/** /api/partners/users/:user_id/company_owner(.:format) */
export function apiPartnersUserCompanyOwnerPath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "users" + "/" + user_id + "/" + "company_owner" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id","format"]);
}

/** /api/partners/users/:user_id/budget_estimate(.:format) */
export function apiPartnersUserBudgetEstimatePath(user_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "users" + "/" + user_id + "/" + "budget_estimate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["user_id","format"]);
}

/** /api/partners/rent_appreciations/:id(.:format) */
export function apiPartnersRentAppreciationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "rent_appreciations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/partners/price_appreciations/:id(.:format) */
export function apiPartnersPriceAppreciationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "price_appreciations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/partners/payment_scenarios_comparison_summary/:id(.:format) */
export function apiPartnersPaymentScenariosComparisonSummaryPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "payment_scenarios_comparison_summary" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/partners/payment_scenario_comparisons/:id(.:format) */
export function apiPartnersPaymentScenarioComparisonPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "payment_scenario_comparisons" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/partners/initial_consultation(.:format) */
export function apiPartnersInitialConsultationPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "initial_consultation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/plaid_events(.:format) */
export function apiPartnersPlaidEventsPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "plaid_events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/plaid_items(.:format) */
export function apiPartnersPlaidItemsPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "plaid_items" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/plaid_link_tokens(.:format) */
export function apiPartnersPlaidLinkTokensPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "plaid_link_tokens" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/demo_portal_state(.:format) */
export function apiPartnersDemoPortalStatePath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "demo_portal_state" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/todos(.:format) */
export function apiPartnersTodosPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "todos" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/rutter_connections(.:format) */
export function apiPartnersRutterConnectionsPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "rutter_connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/partners/sba_applications(.:format) */
export function apiPartnersSBAApplicationsPath(options?: object): string {
  return "/" + "api" + "/" + "partners" + "/" + "sba_applications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
