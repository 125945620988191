import * as React from 'react'

import {
  AccessTimeFilledOutlined,
  CheckCircle,
  CircleOutlined,
} from '@mui/icons-material'
import {
  Button,
  Typography,
  Box,
  Stack,
  useTheme,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Link,
  Skeleton,
} from '@mui/material'
import {grey} from '@mui/material/colors'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {MonochromeChip} from 'src/components/MonochromeChip'
import {
  GetPreapprovedButton,
  PrivacyTooltip,
} from 'src/components/portal/GetPreapprovedButton'
import {preapprovedPortalState} from 'src/components/portal/backwardsCompatiblePreapprovalState'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {SkeletonLoader} from 'src/framework/ui/SkeletonLoader'
import {DataCollectionActions} from 'src/screens/PartnerConnectScreen'
import {
  BudgetEstimate,
  CompanyOwningUser,
  PartnerCompany,
  PartnerDeal,
  User,
} from 'src/types'
import {PortalState} from 'src/types/deal'
import {formatCurrency} from 'src/util/format'
import {formatCurrencyCompact} from 'src/util/format/formatCurrencyCompact'

type StepStatus = 'disabled' | 'notStarted' | 'inProgress' | 'completed'

type CardConfig = {
  newExperienceBanner?: boolean
  chipLabel?: string
  title: string
  budgetDisplay: string | null
  subtitle: string | null
  description: string | null
  description2?: string | null
  ctaTitle: string
  ctaType: 'getPreapproved' | 'email' | null
  getPrequalifiedStep: StepStatus
  addFinancialsStep: StepStatus
  calculatingBudgetStep: StepStatus
}

export const getBudgetDisplay = (
  budgetEstimate: BudgetEstimate,
): string | null => {
  return budgetEstimate.preapprovedBudget
    ? formatCurrency(budgetEstimate.preapprovedBudget?.toString(), {
        precision: 0,
      })
    : budgetRangeDisplay(budgetEstimate.budgetLow, budgetEstimate.budgetHigh)
}

const getCardConfig = (
  user: User,
  deal: PartnerDeal,
  companyOwningUser: CompanyOwningUser,
  budgetEstimate: BudgetEstimate,
  flags: {marketingLaunchJune2024: boolean},
): CardConfig | undefined | null => {
  const portalState = deal.portalState
  const budgetDisplay = getBudgetDisplay(budgetEstimate)

  if (
    [PortalState.PREQUALIFIED, PortalState.ADDITIONAL_FINANCIALS].includes(
      portalState,
    )
  ) {
    if (flags.marketingLaunchJune2024) {
      return {
        title: 'Your waitlist number is',
        budgetDisplay: '1,025',
        subtitle: 'Skip the line by connecting your financials',
        description: null,
        ctaTitle: 'Connect your financials',
        ctaType: 'getPreapproved',
        getPrequalifiedStep: 'completed',
        addFinancialsStep: 'notStarted',
        calculatingBudgetStep: 'disabled',
      }
    }
    return {
      chipLabel: 'How much can you afford?',
      title: budgetDisplay
        ? 'Your estimated budget is'
        : 'We need a bit more information',
      budgetDisplay: budgetDisplay,
      subtitle: 'Add finances to view your actual budget',
      description:
        'By adding your financials, withco can determine how much you can afford to pay for a property.',
      ctaTitle: 'Add your finances',
      ctaType: 'getPreapproved',
      getPrequalifiedStep: 'completed',
      addFinancialsStep: 'notStarted',
      calculatingBudgetStep: 'disabled',
    }
  } else if (portalState === PortalState.PREAPPROVAL_UNDER_REVIEW) {
    if (flags.marketingLaunchJune2024) {
      return null
    }

    return {
      newExperienceBanner: true,
      chipLabel: 'How much can you afford to pay for a property?',
      title: 'Working on your purchasing budget',
      budgetDisplay: null,
      subtitle: null,
      description:
        'withco is determining how much you can afford to pay for a property. Check back soon.',
      ctaTitle: '',
      ctaType: null,
      getPrequalifiedStep: 'completed',
      addFinancialsStep: 'completed',
      calculatingBudgetStep: 'inProgress',
    }
  } else if (preapprovedPortalState(portalState)) {
    return {
      newExperienceBanner: true,
      chipLabel: 'Your Purchasing Budget',
      title: 'Your purchasing budget is',
      budgetDisplay: budgetDisplay,
      subtitle: null,
      description: 'This is the amount you can afford to pay for a property.',
      description2:
        "withco calculates this based on your business's cash flow, projected real estate appreciation in your market, and the projected rent escalation in your area, and withco's investment criteria.",
      ctaTitle: '',
      ctaType: null,
      getPrequalifiedStep: 'completed',
      addFinancialsStep: 'completed',
      calculatingBudgetStep: 'completed',
    }
  } else if (
    portalState === PortalState.DISQUALIFIED ||
    (portalState === PortalState.LEAD && deal.smbInbound)
  ) {
    return {
      chipLabel: 'Your Next Step',
      title: 'Sorry!',
      budgetDisplay: null,
      subtitle: "It looks like we're unable to work together at this time.",
      description:
        "Based on the information you provided, we don't think we'll be able to help your business in the foreseeable future. We require our lease-to-own tenants to have at least $1,000,000 annual revenue, a FICO credit score of at least 650, and at least 2 years of incorporated operating history. We'll reach out if something changes. Please email us at join@with.co if you meet the above criteria and you think we've made a mistake.",
      ctaTitle: 'Email us',
      ctaType: 'email',
      getPrequalifiedStep: 'notStarted',
      addFinancialsStep: 'disabled',
      calculatingBudgetStep: 'disabled',
    }
  }
}

export const budgetRangeDisplay = (low: number | null, high: number | null) => {
  if (low === null || high === null) {
    return null
  }

  return `${formatCurrencyCompact(low)} - ${formatCurrencyCompact(high)}`
}

interface Props {
  deal: PartnerDeal | null
  refetchDeal: () => void
  company: PartnerCompany | null
  refetchCompany: () => void
  user: User | null
  companyOwningUser: CompanyOwningUser | null
  budgetEstimate: BudgetEstimate | null
}
export const NextStepCard = ({
  deal,
  refetchDeal,
  company,
  refetchCompany,
  user,
  companyOwningUser,
  budgetEstimate,
}: Props): JSX.Element | null => {
  const {demoApril2024, marketingLaunchJune2024} = useFlags()
  const theme = useTheme()

  const cardConfig = React.useMemo(
    () =>
      user &&
      deal &&
      companyOwningUser &&
      budgetEstimate &&
      getCardConfig(user, deal, companyOwningUser, budgetEstimate, {
        marketingLaunchJune2024,
      }),
    [user, deal, companyOwningUser, budgetEstimate, marketingLaunchJune2024],
  )

  const cardStepperConfig = React.useMemo(() => {
    if (marketingLaunchJune2024) {
      return [
        {
          title: 'Apply',
          status: cardConfig?.getPrequalifiedStep,
        },
        {
          title: 'Connect your financials',
          status: cardConfig?.addFinancialsStep,
        },
        {
          title: 'Speak to an expert',
          status: cardConfig?.calculatingBudgetStep,
        },
      ]
    }

    return [
      {
        title: 'Get pre-qualified',
        status: cardConfig?.getPrequalifiedStep,
      },
      {
        title: 'Add your finances',
        status: cardConfig?.addFinancialsStep,
      },
      {
        title: 'Calculating your purchasing budget',
        status: cardConfig?.calculatingBudgetStep,
      },
    ]
  }, [cardConfig, marketingLaunchJune2024])

  const getStepIcon = (status: StepStatus | undefined, loading: boolean) => {
    switch (loading ? 'disabled' : status) {
      case 'completed':
        return <CheckCircle sx={{fill: theme.palette.secondary.main}} />
      case 'inProgress':
        return (
          <AccessTimeFilledOutlined sx={{fill: theme.palette.secondary.main}} />
        )
      case 'notStarted':
        return <CircleOutlined sx={{fill: theme.palette.secondary.main}} />
      case 'disabled':
        return (
          <CircleOutlined
            sx={{
              fill: grey[400],
            }}
          />
        )
    }
  }

  const handleFinancialConnectionSuccess = React.useCallback(() => {
    refetchDeal()
    refetchCompany()
  }, [refetchDeal, refetchCompany])

  const cta = React.useMemo(() => {
    switch (cardConfig?.ctaType) {
      case 'getPreapproved':
        return (
          deal && (
            <Stack direction="row" spacing={1} alignItems="center">
              <GetPreapprovedButton
                deal={deal}
                onSuccess={handleFinancialConnectionSuccess}
                buttonProps={{size: 'large'}}
                sx={{width: {sm: 320, xs: '100%'}, fontSize: {sm: 16, xs: 14}}}
                label={cardConfig.ctaTitle}
              />
              <PrivacyTooltip />
            </Stack>
          )
        )
      case 'email':
        return (
          <Button
            variant="contained"
            component={Link}
            href="mailto:join@with.co"
            size="large"
            sx={{width: {sm: 320, xs: '100%'}, fontSize: {sm: 16, xs: 14}}}
          >
            {cardConfig.ctaTitle}
          </Button>
        )
    }
  }, [cardConfig, deal, handleFinancialConnectionSuccess])

  const progress = React.useMemo(() => {
    const result = !(user && deal && budgetEstimate && company)
    return result
  }, [user, deal, budgetEstimate, company])

  const isConnectedAndWaitlisted = React.useMemo(() => {
    return (
      marketingLaunchJune2024 &&
      deal?.portalState === PortalState.PREAPPROVAL_UNDER_REVIEW
    )
  }, [deal, marketingLaunchJune2024])

  return (
    <>
      <Grid container spacing={{xs: 2, sm: 3}}>
        {!demoApril2024 && cardConfig?.newExperienceBanner && (
          <Grid item xs={12}>
            <Paper
              sx={{
                backgroundColor: theme.palette.background.blue,
                borderColor: theme.palette.primary.main,
                padding: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                <MaterialSymbolIcon
                  weight={300}
                  color="primary"
                  sx={{fontSize: '40px'}}
                >
                  Campaign
                </MaterialSymbolIcon>

                <Typography variant="body1">
                  withco is working on a brand new experience to help you own
                  your property. This experience will help you determine how
                  much you can afford to pay for a property, determine your
                  properties value and much more. We&apos;ll share updates soon!
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        )}
        {cardConfig && (
          <>
            <Grid item xs={12} sm={8} marginY={{xs: 1, sm: 2.5}}>
              <Stack direction="column">
                {cardConfig?.chipLabel && (
                  <SkeletonLoader loading={progress}>
                    <MonochromeChip
                      label={cardConfig?.chipLabel}
                      size="small"
                      textColor={theme.palette.primary.main}
                      sx={{width: 'fit-content'}}
                    />
                  </SkeletonLoader>
                )}
                <Stack
                  direction="column"
                  spacing={1}
                  marginTop={{xs: 1, sm: 2}}
                >
                  <Stack>
                    <SkeletonLoader loading={progress} width={400}>
                      <Typography
                        variant="strong"
                        fontSize="2.5rem"
                        lineHeight="1.4"
                      >
                        {cardConfig?.title}
                      </Typography>
                    </SkeletonLoader>
                    <SkeletonLoader loading={progress}>
                      <Typography
                        variant="strong"
                        fontSize="2.5rem"
                        lineHeight="1.4"
                        color={theme.palette.primary.main}
                      >
                        {cardConfig?.budgetDisplay}
                      </Typography>
                    </SkeletonLoader>
                  </Stack>

                  <SkeletonLoader loading={progress} width={400}>
                    <Typography variant="h2">{cardConfig?.subtitle}</Typography>
                  </SkeletonLoader>
                  {cardConfig?.description && (
                    <SkeletonLoader loading={progress} lines={1} width="auto">
                      <Stack direction="column" spacing={2}>
                        <Typography variant="body1">
                          {cardConfig?.description}
                        </Typography>
                        <Typography variant="body1">
                          {cardConfig?.description2}
                        </Typography>
                      </Stack>
                    </SkeletonLoader>
                  )}
                </Stack>
                <Box marginTop={{xs: 3}}>
                  {progress ? (
                    <Skeleton variant="rectangular" height={44} width={320} />
                  ) : (
                    cta
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} marginY={{xs: 1, sm: 2.5}}>
              <Paper
                sx={{
                  backgroundColor: theme.palette.background.blue,
                  borderColor: theme.palette.primary.main,
                  marginTop: {xs: 0, sm: 2},
                  padding: 3,
                }}
              >
                <Stepper
                  activeStep={1}
                  orientation="vertical"
                  connector={
                    <StepConnector
                      sx={{
                        '.MuiStepConnector-line': {
                          borderLeftStyle: 'dashed',
                        },
                      }}
                    />
                  }
                >
                  {cardStepperConfig.map((step, index) => (
                    <Step key={`step${index}`}>
                      <StepLabel
                        icon={getStepIcon(step.status, progress)}
                        sx={{paddingY: 0}}
                      >
                        <SkeletonLoader loading={progress} key={`step${index}`}>
                          <Typography
                            variant="h2"
                            lineHeight="1"
                            fontSize={{xs: '1rem', sm: '1rem'}}
                            color={
                              step.status == 'disabled'
                                ? grey[400]
                                : theme.palette.text.primary
                            }
                          >
                            {step.title}
                          </Typography>
                        </SkeletonLoader>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Paper>
            </Grid>
          </>
        )}
        {company && isConnectedAndWaitlisted && (
          <Grid item xs={12} marginY={{xs: 1, sm: 2.5}}>
            <WaitlistConnections
              company={company}
              refetchCompany={refetchCompany}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

interface WaitlistConnectionsProps {
  company: PartnerCompany
  refetchCompany: () => void
}

const WaitlistConnections = ({
  company,
  refetchCompany,
}: WaitlistConnectionsProps): JSX.Element | null => {
  const {marketingLaunchJune2024} = useFlags()

  if (!marketingLaunchJune2024) return null

  return (
    <Box>
      <Typography variant="strong" fontSize="2rem" lineHeight="1.4">
        {`We'll call you in short order`}
      </Typography>
      <Typography variant="body1">{`In the meantime, please share what you can so we can provide a more in-depth recommendation.`}</Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={{xs: 2, sm: 3}}
        textAlign="center"
      >
        <DataCollectionActions
          company={company}
          refetchCompany={refetchCompany}
        />
      </Box>
    </Box>
  )
}
